import { ClientRouter, replace } from "@nalanj/client-router";
import { BetterForm } from "./components/better-form.js";
import { HamburgerIcon } from "./components/hamburger-icon.js";
import { RouterLink } from "./components/router-link.js";
import { TextInput } from "./components/text-input.js";
import "./plausible.js";
import { SignupForm } from "./components/signup-form.js";
import { fonts } from "./fonts.js";
import "./style/index.css.js";
import { Fx } from "./fx.js";

fonts("Biotif", "Inter");

ClientRouter.onChange = replace;
ClientRouter.start();

Fx.register("router-link", RouterLink);
Fx.register("text-input", TextInput, {
	filters: {
		otp: (value) => value.toUpperCase().trim(),
	},
});
Fx.register("better-form", BetterForm);
Fx.register("signup-form", SignupForm);
Fx.register("hamburger-icon", HamburgerIcon);
