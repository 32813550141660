let prefix = "/";
const knownFonts = {
	Biotif: "biotif/biotif.css",
	Inter: "inter/v3.19/inter.css",
	SourceSerifText: "source-serif/v4.005/source-serif-text.css",
	Chiswick: "chiswick/chiswick-2024-02-01-2.css",
	HolenVintage: "holen-vintage/holen-vintage.css",
};

if (process.env.NODE_ENV === "production") {
	prefix = "https://static.withcardinal.com/webfonts/";
}

/**
 * Loads the given fonts by adding link tags for their files dynamically.
 *
 * @param  {...string} families - the font families to load
 */
export function fonts(...families) {
	for (const family of families) {
		const link = document.createElement("link");
		link.setAttribute("rel", "stylesheet");
		link.setAttribute("href", prefix + knownFonts[family]);
		link.setAttribute("data-client-router-sticky", "");
		document.head.appendChild(link);
	}
}

/**
 * Looks up a known font family's stylesheet url
 *
 * @param {string} family - the name of the font to return the url for
 * @returns The url of the font, or undefined if it isn't a known font
 */
export function fontUrl(family) {
	return prefix + knownFonts[family];
}
