import plausibleEvent from "../plausible";
import { BetterForm } from "./better-form.js";

export class SignupForm extends BetterForm {
	onSuccess() {
		plausibleEvent("signup-success");
	}

	onError() {
		plausibleEvent("signup-error");
	}
}
