import { Fx } from "../fx.js";

export class TextInput extends Fx {
	input;
	opts;
	_filter;

	attach(opts) {
		this.opts = opts;
		this.input = this.querySelector("input");
		this.wrap = this.querySelector('div[class="text-input"]');

		this._filter = this.getAttribute("filter");

		this.onChange();
		this.listen(this.input, "input", () => this.onChange());
	}

	attributesChangedCallback(name, oldVal, newVal) {
		if (name === "filter") {
			this._filter = newVal;
		}
	}

	get filter() {
		if (this._filter && this.opts?.filters[this._filter]) {
			return this.opts?.filters[this._filter];
		}

		return undefined;
	}

	onChange() {
		let value = this.input.value;

		if (this.filter) {
			value = this.filter(value);
			this.input.value = value;
		}

		if (value !== "") {
			this.wrap.dataset.filled = "true";
		} else {
			this.wrap.dataset.filled = "false";
		}
	}
}
